$(document).ready(function() {

    var path_to_lang;

    // select the proper language
    // https://datatables.net/manual/i18n
    switch (current_language) {
    case 'ro':
        path_to_lang = '//cdn.datatables.net/plug-ins/1.10.16/i18n/Romanian.json';
        break;
    case 'hu':
        path_to_lang = '//cdn.datatables.net/plug-ins/1.10.16/i18n/Hungarian.json';
        break;
    default:
        path_to_lang = '//cdn.datatables.net/plug-ins/1.10.16/i18n/English.json';
        break;
    }


    // wait until the tabel creation is finalized by djangocms.googlemap.js script, then 
    // initialize tabel sorting
    function isTableReady() {

        // variable is undefined or null because the DataTable plugin has called outside the 'Google Map plugin' template
        if (typeof tableReady === 'undefined' || tableReady === null) {

            if ($.fn.dataTable.isDataTable('.table-smart')) {
                // table has been initialized already
                table = $('.table-smart').DataTable();
            } else {
                // table is initializing now
                $('.table-smart').DataTable({
                    'order': [[ 0, 'asc' ]],
                    'responsive': true,
                    'language': {
                        'url' : path_to_lang,
                    },
                    'pagingType': 'numbers',
                }); 
            }}

        // DataTable has been called from Google Map plugin template, but the table creation is hasn't been finished yet
        // wait for 100 ms
        else if (tableReady === false) {
            console.log('wait another 100 miliseconds');
            setTimeout(isTableReady, 100); }
        
        // DataTable has been called from Google Map plugin template, and the table creation is finalized:
        // call the DataTable plugin on the ready table
        else {
            $('.table-smart').DataTable( {
                'order': [[ 0, 'asc' ]],
                'responsive': true,
                'language': {
                    'url' : path_to_lang,
                },
                'pagingType': 'numbers',
            });
        }
    }

    // call the function
    setTimeout(isTableReady, 100);

});
